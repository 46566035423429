import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"

import DefaultLayout from "~/layouts/default"
import SEO from "~/components/SEO"
import SocialBar from "~/components/SocialBar"
import Section from "~/components/section/Section"
import Content from "~/components/Content"
import { TextColumn, SectionLayout } from "~/components/section/shared"

export default function About({ data }) {
  const { sections, contact } = data.contentfulAbout
  const thumbnail = data.contentfulAsset

  return (
    <DefaultLayout socialBar={false}>
      <SEO title={"About"} thumbnail={thumbnail} />
      {sections.map((section, index) => (
        <Section key={index} section={section} />
      ))}
      <Content theme={"regular"} width={"wide"}>
        <ContactSectionLayout>
          <ContactColumn text={contact} />
          <CenteredSocialBar style={{ display: "flex" }} />
        </ContactSectionLayout>
      </Content>
    </DefaultLayout>
  )
}

const ContactColumn = styled(TextColumn)`
  flex: 1 1 50%;
`

const ContactSectionLayout = styled(SectionLayout)`
  padding-top: 80px;
  padding-bottom: 104px;
`

const CenteredSocialBar = styled(SocialBar)`
  display: flex;
  justify-content: center;
  flex: 1 1 50%;
`

export const query = graphql`
  query AboutQuery {
    contentfulAbout(contentful_id: { eq: "MWVkcfUIUpDePufAyV1P7" }) {
      title
      contact {
        json
      }
      sections {
        __typename
        ... on ContentfulSectionTwoColumn {
          theme
          size
          left {
            json
          }
          right {
            title
            description
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulSectionProfile {
          theme
          size
          image {
            title
            description
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          text {
            json
          }
        }
        ... on ContentfulSectionNarrowColumn {
          theme
          left {
            json
          }
          right {
            json
          }
        }
      }
    }
    contentfulAsset(contentful_id: { eq: "4cELui0WBH1v6Vo8BXjQE1" }) {
      fixed {
        src
        height
        width
      }
    }
  }
`
